<template>
	<div>
		<div class="child-top" style="padding-top: 0">
			<div class="input-from">
				<el-date-picker
					v-model="date"
					type="daterange"
					align="right"
					unlink-panels
					format="yyyy-MM-dd"
					value-format="yyyy-MM-dd"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					:picker-options="pickerOptions"
					@change="create(1)"
					class="picker_date"
				>
				</el-date-picker>
				<el-cascader
					@change="searchCreate"
					style="float: left"
					placeholder="请选择运动队"
					clearable
					v-model="deparmentId"
					:options="departmentOptions"
					:show-all-levels="false"
					collapse-tags
					:props="{ children: 'children', label: 'name', value: 'uuid', emitPath: false, multiple: true }"
				></el-cascader>
				<div>
					<el-input
						@keyup.enter.native="create(1)"
						@clear="create(1)"
						class="left-child-input left-child-input-content"
						clearable
						placeholder="请输入标题关键字"
						style="width: 200px; float: left"
						suffix-icon="iconfont iconsousuo"
						v-model="inputValue"
					>
					</el-input>
					<el-button class="searchBtn" round @click="create(1)">搜索</el-button>
				</div>
			</div>

			<div class="operation-button">
				<span
					class="fanhui"
					@click="
						() => {
							$router.push({ path: '/textItem/lactateTextDetail', query: {
								img: infoObj.img,
								name: infoObj.name,
								sex: infoObj.sex,
								old: infoObj.old,
								height: infoObj.height,
								weight: infoObj.weight,
								sport_team: infoObj.sport_team,
								department_name: infoObj.department_name
							} })
						}
					"
					><i class="iconfont iconfanhui"></i>返回</span
				>
				<!-- <el-button @click="getYLJdata" class="down_btn" operation-button round v-if="tbButtonShow">
					<span>同步易力加数据</span>
				</el-button>
				<el-button @click="addDataLog" class="new_btn" operation-button round :disabled="$store.getters.permissionsStr('新增')">
					<span>新增</span>
				</el-button>
				<el-button @click="delMoreData" class="del_btn" round :disabled="$store.getters.permissionsStr('删除') || !tableDataSelectArr.length">
					<span>删除</span>
				</el-button> -->
			</div>
		</div>

		<div class="person_info_view">
			<div class="input-from lac_detail_top_info">
				<el-avatar :size="50">
					<img :src="infoObj.img" />
				</el-avatar>
				<span>姓名:</span>{{infoObj.name}} <span>性别:</span>{{infoObj.sex == 1 ? '男' : '女'}}<span>身高:</span>{{infoObj.height}}cm <span>体重:</span>{{infoObj.weight}}kg <span>所属运动队:</span>{{infoObj.sport_team}} <span>部门:</span>{{infoObj.department_name}}
			</div>
			<div class="info_btn">
				<el-tooltip class="item" effect="dark" content="请选择2条训练类型相同的数据" placement="left">
					<i class="el-icon-info"></i>
				</el-tooltip>

				<el-button @click="goDetail" round>对比分析</el-button>
			</div>
		</div>

		<div class="content-flex-pages" style="background: #fff">
			<el-table :data="tableData" class="content-test-table" style="width: 100%" v-loading="tableLoading">
				<el-table-column type="selection" width="45"></el-table-column>
				<el-table-column label="序号" type="index" width="45">
					<template slot-scope="scope">
						{{ (currentPage - 1) * 10 + scope.$index + 1 }}
					</template>
				</el-table-column>
				<el-table-column label="标题" prop="title" show-overflow-tooltip></el-table-column>
				<el-table-column label="测试日期" prop="date" show-overflow-tooltip></el-table-column>
				<el-table-column label="训练内容" prop="content" show-overflow-tooltip></el-table-column>
				<el-table-column label="训练类型" prop="type" show-overflow-tooltip></el-table-column>
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button class="right20" style="color: #0055e9" size="medium" type="text" @click="editorData(scope.row.th, scope.row, scope.$index)">编辑 </el-button>
						<el-button class="right20" style="color: #1a9f16" size="medium" type="text" @click="lookData(scope.row.th, scope.row, scope.$index)">查看 </el-button>
						<el-button @click="delTableData(scope.row)" class="redColor" size="medium" style="color: #fd3258" type="text">删除 </el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination
				:current-page.sync="currentPage"
				:page-size="10"
				:total="tableTotal"
				@current-change="handleCurrentChange"
				class="table-pagination"
				layout="total, prev, pager, next , jumper"
			></el-pagination>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			date: [],
			deparmentId: '',
			inputValue: '',
			pickerOptions: [],
			departmentOptions: [],

			tableData: [
				{ title: '男子皮艇2000米测试', date: '2023-08-15', content: '2000米皮艇', type: '持续训练', th: 1 },
				{ title: '男子皮艇2000米测试', date: '2023-09-10', content: '2000米皮艇', type: '持续训练', th: 2 },
			],
			tableLoading: false,

			currentPage: 1,
			tableTotal: 2,
			infoObj: {}

		}
	},
	mounted() {
		this.infoObj = {
			name: this.$route.query.name,
			sex: this.$route.query.sex,
			old: this.$route.query.old,
			height: this.$route.query.height,
			weight: this.$route.query.weight,
			img: this.$route.query.img,
			sport_team: this.$route.query.sport_team,
			department_name: this.$route.query.department_name
		}
	},
	methods: {
		create() {},
		searchCreate() {},
		editorData(type) {
			this.$router.push({
				path: '/textItem/lactateTextAdd',
				query: {
					type: type,
					img: this.infoObj.img,
					name: this.infoObj.name,
					sex: this.infoObj.sex,
					old: this.infoObj.old,
					height: this.infoObj.height,
					weight: this.infoObj.weight,
					sport_team: this.infoObj.sport_team,
					department_name: this.infoObj.department_name
				},
			})
		},
		lookData(type) {
			this.$router.push({
				path: '/textItem/lactateTextAdd',
				query: {
					type: type,
					img: this.infoObj.img,
					name: this.infoObj.name,
					sex: this.infoObj.sex,
					old: this.infoObj.old,
					height: this.infoObj.height,
					weight: this.infoObj.weight,
					sport_team: this.infoObj.sport_team,
					department_name: this.infoObj.department_name
				},
			})
		},
		delTableData() {},
		handleCurrentChange(val) {
			this.currentPage = val
		},
		goDetail() {
			this.$router.push({path: '/textItem/lactateTextDetail', query: {
				img: this.infoObj.img,
				name: this.infoObj.name,
				sex: this.infoObj.sex,
				old: this.infoObj.old,
				height: this.infoObj.height,
				weight: this.infoObj.weight,
				sport_team: this.infoObj.sport_team,
				department_name: this.infoObj.department_name
			}})
		},
	},
}
</script>

<style lang="scss" scoped>
.child-top {
	border-radius: 5px;
	.input-from {
		float: left;
		min-width: 1100px;
		margin-bottom: 0;

		.picker_date {
			float: left;
			margin-top: 20px;
		}

		& > .el-select {
			width: 150px;
			float: left;
			margin-top: 20px;
		}

		.el-select,
		.el-input {
			margin-top: 20px;
		}

		.el-input.el-input--suffix.left-child-input {
			float: left;
			width: 200px;
		}

		.el-cascader {
			margin-top: 18px;
			min-width: 200px;
		}

		.searchBtn {
			margin-top: 20px;
		}
	}

	.operation-button {
		// float: left;
		margin-bottom: 10px;

		.fanhui {
			font-size: 16px;
			color: #666;
			display: inline-block;
			padding-top: 5px;
			margin-left: 40px;
			margin-right: 20px;
			i {
				margin-right: 5px;
				color: #000;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}
}
.person_info_view {
	background: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	border-radius: 5px;
	.lac_detail_top_info {
		display: flex;
		align-items: center;
		font-size: 16px;
		color: #333333;
		span {
			margin: 0 5px 0 20px;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	.info_btn {
		display: flex;
		align-items: center;
		i {
			font-size: 20px;
			color: #999999;
		}
		.el-button {
			margin: 0 20px;
			color: #fff;
			background: #0055e9;
			border-color: #0055e9;
		}
	}
}

.content-flex-pages {
	padding-bottom: 10px;
	margin-top: 10px;
}
</style>
